 export const environment = {
  applicationStageCount: 8,
  apiUrl: 'https://api.int.ohcs.prolinkplus.prolinksolutions.com/api',
  clientName: 'ohcs',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_UyjmnZWEk',
  cognitoUserPoolWebClientId: '17n5isdf7k84t9cnlkrvms6f9b',
  googleAnalyticsId: '',
  hotjarId: '',
  loadZenDesk: false,
  production: false,
  allowLanguageChanges: true,
  recaptchaSiteKey: '6LcCT3EfAAAAAI-Ha77qCH0zDxFxAX4e1vR8E_U3',
  setTimeout: 30,
  smartyStreetsKey: '104582367074633472',
  stateAbbreviation: 'OR',
  stateId: 38,
  timeOut: 1800,
  timestamp: '12/11/2023 11:09:52 PM',
  title: 'Oregon HAF - ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: '',
  showReasonableAccommodation: true,
  showLanguageAssistance: true,
  showDiscriminationComplaints: true,
  showLoginPageHeroBanner: true,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}, { name: 'Русский', value:'ru'}, { name: '中文', value:'zh'}, { name: 'Tiếng Việt', value:'vi'}]
};
